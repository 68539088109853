<template>
  <div id="customer-service" class="customer-service">
    <div
      v-show="!isOpen"
      @click="open"
      @mousedown="mousedown"
      ref="customerServiceBtnRef"
      class="customer-service-btn"
      :style="btnStyle"
    >
      <img :src="webChatIcon" alt="" />
      <span>客服</span>
    </div>
    <div v-if="isHandleClick" v-show="isOpen && iframeUrl" class="customer-service-box">
      <div @click="close" class="drop-down">
        <img :src="dropDownIcon" alt="" />
      </div>
      <div class="customer-service-footer"></div>
      <iframe
        v-if="iframeUrl"
        :src="iframeUrl"
        class="customer-service-iframe"
        width="100%"
        height="100%"
        frameborder="0"
      />
    </div>
  </div>
</template>

<script>
const webChatIcon = require("@/assets/img/webChat/webChat.png");
const dropDownIcon = require("@/assets/img/webChat/drop-down.png");

export default {
  name: "CustomerService",
  data() {
    return {
      webChatIcon: webChatIcon,
      dropDownIcon: dropDownIcon,
      move: false,
      isOpen: false,
      isHandleClick: false,
      top: "",
      left: "",
      iframeUrl: ""
    };
  },
  computed: {
    btnStyle() {
      if (!this.top) return {};
      return {
        top: this.top,
        left: this.left
      };
    }
  },
  created() {
    if (localStorage.chatBtnStyle) {
      const obj = JSON.parse(localStorage.chatBtnStyle);
      this.top = obj.top;
      this.left = obj.left;
    }
  },
  methods: {
    getIframeUrl() {
      this.iframeUrl = `https://a8-im.7x24cc.com/phone_webChat.html?accountId=N000000052132&chatId=7443010a-b84e-4afd-b896-84187534f856`;
    },
    open() {
      if (!this.move) {
        this.getIframeUrl();
        this.isOpen = true;
        this.isHandleClick = true;
      }
    },
    close() {
      this.isOpen = false;
    },
    mousedown(e) {
      e.preventDefault();
      const el = this.$refs.customerServiceBtnRef;
      const body = document.getElementsByTagName("body")[0];
      const parentHeight = body.offsetHeight;
      const parentWidth = body.offsetWidth;
      const disX = e.pageX - el.offsetLeft;
      const disY = e.pageY - el.offsetTop;
      document.onmousemove = function(de) {
        const moveX = Math.abs(e.pageX - de.pageX);
        const moveY = Math.abs(e.pageY - de.pageY);
        // 移动距离小于5时，不控制移动
        if (moveX < 5 && moveY < 5) {
          this.move = false;
          return;
        } else {
          this.move = true;
        }
        // 左边界
        let left = de.pageX - disX;
        if (left < 0) {
          left = 0;
        }
        // 右边界
        if (left > parentWidth - el.offsetWidth) {
          left = parentWidth - el.offsetWidth;
        }
        // 上边界
        let top = de.pageY - disY;
        if (top < 0) {
          top = 0;
        }
        // 下边界
        if (top + el.offsetHeight >= parentHeight) {
          top = parentHeight - el.offsetHeight;
        }
        el.style.left = left + "px";
        el.style.top = top + "px";
        el.style.right = "0";
        el.style.bottom = "0";
        el.style.pointerEvents = "none";
      };
      document.onmouseup = function() {
        if (!this.move) {
          document.onmousemove = document.onmouseup = null;
          return;
        } else {
          this.move = false;
        }
        // 当前位置转为%
        const top = (el.offsetTop / parentHeight) * 100;
        const left = (el.offsetLeft / parentWidth) * 100;
        localStorage.chatBtnStyle = JSON.stringify({
          left: left.toFixed(2) + "%",
          top: top.toFixed(2) + "%"
        });
        setTimeout(() => {
          el.style.pointerEvents = "auto";
          document.onmousemove = document.onmouseup = null;
        }, 0);
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.customer-service {
  .customer-service-btn {
    width: 40px;
    height: 100px;
    right: 20px;
    bottom: 20px;
    display: flex;
    z-index: 999999;
    position: fixed;
    cursor: pointer;
    border-radius: 5px;
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ff6e4c;
    img {
      width: 25px;
    }
    span {
      color: #ffffff;
      font-size: 16px;
      margin-top: 10px;
      letter-spacing: 5px;
      writing-mode: vertical-rl;
    }
  }
  .customer-service-box {
    width: 320px;
    height: 500px;
    right: 18px;
    bottom: 0;
    position: fixed;
    z-index: 999999;
    .drop-down {
      top: 10px;
      right: 10px;
      cursor: pointer;
      position: absolute;
      img {
        width: 26px;
      }
    }
    .customer-service-footer {
      left: 0;
      bottom: 0;
      width: 100%;
      height: 18px;
      cursor: pointer;
      position: absolute;
      background-color: #ffffff;
    }
  }
}
</style>
